import { Injectable } from '@angular/core';
import { IDriverDelivery, SignaturePackage } from '@prf/shared/domain';

// TODO/NOTES:
// <hline> instead of seperator ---- lines
// https://download4.epson.biz/sec_pubs/pos/reference_en/epos_print/ref_epos_print_xml_en_xmlforcontrollingprinter_hline.html

export interface PrintCompanyInfo {
  name: string;
  address: string;
  phone: string;
  email: string;
}

export interface PrintDeliveryProduct {
  productNo: string;
  description: string;
  actualQuantity: number;
  returnQuantity: number;
  ean: string;
}

export interface PrintReceiptData {
  companyInfo: PrintCompanyInfo;
  isDraft: boolean;
  deliverySlipNo: string;
  deliveryDate: string;
  marketDeliverySlipEmail: string;
  products: PrintDeliveryProduct[];
  signature: SignaturePackage | null;
  currentDate: string;
  currentTime: string;
  totalActualQuantity: number;
  totalReturnQuantity: number;
}

@Injectable({
  providedIn: 'root',
})
export class EpsonPrintService {
  private PRINT_CONFIG = {
    columns: 48,
    font: 'font_e',
    fontSmoothing: 'true',
    lang: 'de',
  } as const;

  private WORD_REPLACEMENTS = {
    Gefüllt: 'Gef.',
    Gefüllte: 'Gef.',
    Getrocknet: 'Getr.',
    Getrocknete: 'Getr.',
    Knoblauch: 'Knobl.',
    ohne: 'o.',
  } as const;

  constructor() {}

  public printDeliverySlip(
    delivery: IDriverDelivery,
    isDraft: boolean,
    signaturePackage: SignaturePackage | null,
  ): void {
    const products: PrintDeliveryProduct[] = this.mapDeliveryProductsToPrintProducts(
      delivery.deliveryProducts,
    );

    // TODO: add types for keys... ie pick/keyof from IDeliveryProduct
    const calculateTotal = (key: 'actualQuantity' | 'returnQuantity'): number => {
      return products.reduce((sum, product) => sum + product[key], 0);
    };

    const receiptData: PrintReceiptData = {
      companyInfo: {
        name: 'PALDO GmbH',
        address: 'Heinrich-Brauns-Str. 17, 45355 Essen',
        phone: '0201 17754771',
        email: 'info@paldo.de',
      },
      isDraft: isDraft,
      deliverySlipNo: delivery.deliverySlipNumber!,
      deliveryDate: new Date(delivery.deliveryDate).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      products: products,
      signature: signaturePackage,
      marketDeliverySlipEmail: delivery.marketDeliverySlipEmail,
      currentDate: new Date().toLocaleString('de-DE', {
        weekday: 'short',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      currentTime: new Date().toLocaleString('de-DE', {
        hour: '2-digit',
        minute: '2-digit',
      }),
      totalActualQuantity: calculateTotal('actualQuantity'),
      totalReturnQuantity: calculateTotal('returnQuantity'),
    };

    this.printWithTMAssistant(receiptData);
  }

  private printWithTMAssistant(receiptData: PrintReceiptData): void {
    console.log('PrintService: printWithTMAssistant - receiptData', receiptData);
    const appScheme = 'tmprintassistant://';
    const host = 'tmprintassistant.epson.com/';
    const action = 'print?';
    const success = encodeURIComponent(window.location.href);
    const ver = '1';
    const timeout = '45000';
    const dataType = 'eposprintxml';
    const reselect = 'yes';

    const xmlData = this.generateXmlData(receiptData);

    const urlData =
      `${appScheme}${host}${action}` +
      `success=${success}&` +
      `ver=${ver}&` +
      `timeout=${timeout}&` +
      `data-type=${dataType}&` +
      `reselect=${reselect}&` +
      `data=${encodeURIComponent(xmlData)}`;

    console.log(xmlData);

    window.location.href = urlData;
  }

  private generateXmlData(receiptData: PrintReceiptData): string {
    let xml = `
      <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        <layout type="receipt" width="800" height="0" margin-top="20" margin-bottom="0" offset-cut="0" offset-label="0"/>
        <text lang="${this.PRINT_CONFIG.lang}"/>
        <text font="${this.PRINT_CONFIG.font}"/>
        <text smooth="${this.PRINT_CONFIG.fontSmoothing}"/>
        <text width="1" height="1"/>

        ${this.generatePaldoLogo()}
        <feed unit="3"/>

        <text align="center"/>
        <text>${receiptData.companyInfo.name}</text>
        <feed line="1"/>

        <text>${receiptData.companyInfo.address}</text>
        <feed line="1"/>

        <text>T: ${receiptData.companyInfo.phone}   E: ${receiptData.companyInfo.email}</text>
        <feed line="1"/>
        <feed unit="4"/>

        <text width="2" height="2"/>
        <text>Lieferschein</text>
        <feed line="1"/>

        <text width="2" height="2"/>
        <text em="true">${receiptData.deliverySlipNo}</text>
        <feed line="1"/>
        <text ul="false"></text>
        <text width="1" height="1"/>
        <text>vom ${receiptData.deliveryDate}</text>
        <text em="false"></text>
        <feed line="1"/>

        ${this.generateDraftTopSection(receiptData.isDraft)}
        <feed line="1"/>

        ${this.generateProductsSection(receiptData.products, 'center')}

        ${this.generateTotalAndTemperatureSection(receiptData.totalActualQuantity, receiptData.totalReturnQuantity)}

        ${this.generateSignatureSection(
          receiptData.isDraft,
          receiptData.signature,
        )}

        ${this.generateDraftBottomLegalHintSection(receiptData.isDraft)}

        <feed line="1"/>
        <text align="left">Dieser Lieferschein wird als PDF an die E-Mail-&#10;Adresse ${receiptData.marketDeliverySlipEmail} gesendet.</text>
        <feed line="2"/>

        ${this.generateTimestamp(receiptData.currentDate, receiptData.currentTime)}
        <feed line="2"/>

        <cut type="feed"/>
      </epos-print>
    `;

    // Remove all empty lines.
    xml = xml.replace(/^\s*[\r\n]/gm, '');

    // Remove all leading whitespace.
    xml = xml.replace(/^\s+/gm, '');

    return xml;
  }

  private formatColumn(text: string, width: number, align: 'left' | 'right' = 'left'): string {
    const ellipsis = '...';
    if (text.length > width) {
      if (align === 'left') {
        return text.substring(0, width - ellipsis.length) + ellipsis;
      } else {
        return ellipsis + text.substring(text.length - width + ellipsis.length);
      }
    } else {
      return align === 'left' ? text.padEnd(width) : text.padStart(width);
    }
  }

  // TODO: Add tests for this method, checking the auto lower/uppercasify for the replaced word.
  private replaceKeywords(text: string): string {
    const words = text.split(/\s+/);
    const replacedWords = words.map((word) => {
      const lowerWord = word.toLowerCase();
      for (const [key, value] of Object.entries(this.WORD_REPLACEMENTS)) {
        if (lowerWord === key.toLowerCase()) {
          const replaced =
            word[0] === word[0].toUpperCase()
              ? value[0].toUpperCase() + value.slice(1)
              : value.toLowerCase();
          return replaced;
        }
      }
      return word;
    });

    return replacedWords.join(' ');
  }

  private generateSeparator(): string {
    return `<text>${'-'.repeat(this.PRINT_CONFIG.columns)}</text>`;
  }

  private generateDraftTopSection(isDraft: boolean): string {
    const EMPTY_SPACE = ' ';

    if (!isDraft) return '';
    return `
      <text width="2" height="2"/>
      <text reverse="true" ul="false" em="false"/>
      <text align="center">${EMPTY_SPACE}ENTWURF${EMPTY_SPACE}</text>
      <text width="1" height="1"/>
      <text reverse="false" ul="false" em="false"/>
      <feed line="1"/>
    `;
  }

  private generateTotalAndTemperatureSection(
    totalActualQuantity: number,
    totalReturnQuantity: number,
  ): string {
    const LEFT_COL_WIDTH = 36;
    const RIGHT_COL_WIDTH = 12;

    return `
      <text>${
        this.formatColumn('Die Kühltemperatur von 3°C - 7°C', LEFT_COL_WIDTH) +
        this.formatColumn('Gesamt', RIGHT_COL_WIDTH, 'right')
      }</text>
      <feed line="1"/>
      <text>${this.formatColumn('wurde eingehalten.', LEFT_COL_WIDTH)}</text>
      <text em="true">${this.formatColumn(
        `${totalActualQuantity}/${totalReturnQuantity}`,
        RIGHT_COL_WIDTH,
        'right',
      )}</text>
      <text em="false"></text>
      <feed line="1"/>
    `;
  }

  private generateSignatureSection(
    isDraft: boolean,
    signature: SignaturePackage | null,
  ): string {
    if (isDraft || !signature) return '';

    return `
      <text align="left">Die Lieferung wurde geprüft, entgegengenommen&#10;und unterschrieben von:</text>
      <feed line="1"/>
      ${signature.monoImageTag}
      <feed unit="2"/>
      <hline x1="0" x2="${(signature.width || 110) + 16}" style="medium" />
      <feed unit="1"/>
      <text> ${signature.signaturePerson}</text>
      <feed line="1"/>
    `;
  }

  private generateDraftBottomLegalHintSection(isDraft: boolean): string {
    if (!isDraft) return '';
    return `
      <feed line="1"/>
      <text align="left">Lieferschein vor Lieferungsabschluss (Entwurf), dieser Beleg ist nicht rechtsverbindlich.</text>
      <feed line="1"/>
    `;
  }

  private generateProductsSection(
    products: PrintDeliveryProduct[],
    barcodeAlignment: 'left' | 'center' | 'right',
  ): string {
    const PRODUCT_NO_WIDTH = 9;
    const DESCRIPTION_WIDTH = 29;
    const QUANTITY_WIDTH = 10;

    if (this.PRINT_CONFIG.columns !== PRODUCT_NO_WIDTH + DESCRIPTION_WIDTH + QUANTITY_WIDTH) {
      window.alert('ERROR - EPSON Print: Column widths do not match max width.');
      throw new Error('EPSON print: Column widths do not match max width.');
    }

    // Offset used in order to allow to description rows "blend" into the quantity col. Ie description rows should be longer than its header, to allow for more room for description.
    const DESCR_QUANT_HEADER_COL_OFFSET = 8;
    const header = `<text>${
      this.formatColumn('Art-Nr.', PRODUCT_NO_WIDTH) +
      this.formatColumn('Bezeichnung', DESCRIPTION_WIDTH - DESCR_QUANT_HEADER_COL_OFFSET) +
      this.formatColumn('Lieferm./Rückn.', QUANTITY_WIDTH + DESCR_QUANT_HEADER_COL_OFFSET, 'right')
    }</text>`;

    const DESCR_QUANT_ROW_COL_OFFSET = 3;
    const productLines = products
      .map((product) => {
        const replacedDescription = this.replaceKeywords(product.description);

        return `
        <text>${
          this.formatColumn(product.productNo, PRODUCT_NO_WIDTH) +
          this.formatColumn(replacedDescription, DESCRIPTION_WIDTH + DESCR_QUANT_ROW_COL_OFFSET) +
          this.formatColumn(
            `${product.actualQuantity}/${product.returnQuantity}`,
            QUANTITY_WIDTH - DESCR_QUANT_ROW_COL_OFFSET,
            'right',
          )
        }</text>
        <feed line="1"/>
        <feed unit="2"/>
        <barcode type="ean13" hri="none" font="font_a" width="4" height="32" align="${barcodeAlignment}">${product.ean}</barcode>
        ${this.generateSeparator()}
        <feed line="1"/>
      `;
      })
      .join('\n');

    return `
      <text align="left"/>
      ${header}
      <feed line="1"/>
      ${this.generateSeparator()}
      <feed line="1"/>
      ${productLines}
    `;
  }

  private generateTimestamp(date: string, time: string): string {
    return `
      <text>${
        this.formatColumn(date, 28) + this.formatColumn(`Uhrzeit: ${time}`, 20, 'right')
      }</text>
      `;
  }

  private generatePaldoLogo(): string {
    return '<image width="130" height="29" align="center" color="color_1" mode="mono">AAAAAAAAAAAAAAAAAAD/AAB//4AAcAD/AAH//4AAA//gAH//8ADwAP+AAf//8AAP7/AAH4P4APgAH4AAPwP8AB+A/AAfgfwB+AAfgAA/AP8APgB+AB+A/AH4AB+AAD8AP4B+AD4AH4D+AfwAH4AAPwAfgPwAPwAfgH4D/AAfgAA/AB/A/AAfgB+AfgP+AB+AAD8AD+H4AB+AH4B+B34AH4AAPwAP4fgAH4AfgP4HfwAfgAA/AA/h+AAfwB+A/g5/AB+AAD8AB/P4AA/AH4D+Dj+AH4AAPwAH8/gAD8AfgfwcP4AfgAA/AAfz+AAPwB+D+BwfgB+AAD8AB/P4AA/AH//wOB/AH4AAPwAH8/gAD8Af/8A4D8AfgAA/AAfz+AAPwB+UADAP4B+AAD8AB/P4AB/AH4AAf+/gH4AAPwAP4fgAH8AfgAB//+AfgAA/AA/h+AAfgB+AAP//8B+AAD8AD+H4AB+AH4AA4APwH4AAPwAfwPwAH4AfgAHAA/gfgAA/AB+A/AA/AB+AAcAB+B+AAD8APwB+AD4AH4ADgAH8H4AAPwB+AD4AfgAfgAOAAP4fgAA/A/wAHwD8AB+ABwAAfx///z//+AAP5/AAH4AHAAA/n///P//AAAP/4AAAAAAAAAIAAAAAAAAAAP8AAA==</image>';
  }

  // TODO: Type for deliveryProduct... productId vs id...
  private mapDeliveryProductsToPrintProducts(deliveryProducts: any[]): PrintDeliveryProduct[] {
    return deliveryProducts.map((dp) => ({
      productNo: dp.product.productNo,
      description: dp.product.description,
      actualQuantity: dp.actualQuantity,
      returnQuantity: dp.returnQuantity,
      ean: dp.product.ean,
    }));
  }
}

// via: https://files.support.epson.com/pdf/pos/bulk/epos-print_xml_um_en_revac.pdf
//
// Supplementary explanation
// ❏ Grayscale printing is allowed in the standard mode, not allowed in the page mode.
// ❏ We cannot guarantee the accuracy of reading a barcode or 2D symbol printed in grayscale. Print it in
// black and white.
// ❏ In order to print a raster image at a high speed, set "align" to "left" and set "width" to a multiple of 8
// which does not exceed the sheet width of the printer.
// ❏ In the page mode, set the print position of the image so that the image does not extend beyond the
// print area.
// ❏ If you set to print a raster image in grayscale, the amount of data of the image increases and may be
// printed intermittently, which causes white streaks on printout.
// ❏ In the page mode, the bottom left corner of the raster image is aligned with the start position for printing the image. The print start position is not automatically moved.
// ❏ The "align" setting is ignored in the page mode.
// ❏ When setting "align" in the standard mode, set it at the beginning of a line.
// ❏ The "align" setting specified in this element is also applied to <text>, <logo>, <barcode>, and <symbol>.
// ❏ Create a raster image using the ePOS-Print XML generation tool or your application. When using your
// application, follow the instruction below for 2 colors image or 16 colors image, whichever you want to
// create.
//
