<div class="grid-container">
  <div class="header-cell gr-col-1">Artikel</div>
  <div class="header-cell gr-col-2">Menge</div>
  <div class="header-cell gr-col-3">Retoure</div>
  <ng-container *ngFor="let item of items; let i = index">
    <div #gridRow class="content-cell gr-col-1">
      <span class="font-bold" style="color: #454545;">{{ item.product?.productNo }}</span>
      <span class="ml-1 mr-1">•</span>
      <span>{{ item.product?.description }}</span>
    </div>
    <div class="content-cell gr-col-2">
      <!-- ACTUAL QUANTITY-->
      <prf-input *ngIf="isEditable"
                 [(ngModel)]="item.actualQuantity"
                 [disabled]="!isEditable"
                 [isFocussed]="itemIsFocussed(item, 'actualQuantity')"
                 [readonly]="true"
                 (hasFocus)="setFocussedItem(item, 'actualQuantity')"
      />
      <ion-text *ngIf="!isEditable" color="tertiary">
        <h4>{{ item.actualQuantity }}</h4>
      </ion-text>
    </div>
    <div class="content-cell gr-col-3">
      <!-- RETURN QUANTITY-->
      <prf-input *ngIf="isEditable"
                 [(ngModel)]="item.returnQuantity"
                 [isFocussed]="itemIsFocussed(item, 'returnQuantity')"
                 [readonly]="true"
                 (hasFocus)="setFocussedItem(item, 'returnQuantity')"
      />
      <ion-text *ngIf="!isEditable" color="tertiary">
        <h4>{{ item.returnQuantity }}</h4>
      </ion-text>
    </div>
  </ng-container>
  <!--  Workaround: SPACER to always have space to move grid up, when the numpad is in front of it -->
  <div style="visibility: hidden; height: 50vh; width: 100%;"></div>
  <!--  <div *ngIf="enableScrollSpacer" style="outline: 4px solid yellow; height: 50vh; width: 100%;">-->
</div>
