import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MOBILE_ROUTE_PATHS } from '../../app.routes';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeliveryTour, IDriverDelivery } from '@prf/shared/domain';
import { DeliveryMarketsTourPageService } from '../delivery-markets-tour/delivery-markets-tour-page.service';
import { ProcessedDelivery } from '../../shared/services/db/app-indexed-db.service';
import { EpsonPrintService } from '../../shared/services/print/epson-print.service';

@Component({
  selector: 'prf-delivery-done-details',
  templateUrl: './delivery-done-details.page.html',
  styleUrls: ['./delivery-done-details.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class DeliveryDoneDetailsPage {
  private router = inject(Router);
  private printService = inject(EpsonPrintService);
  protected deliveryMarketsTourPageService = inject(DeliveryMarketsTourPageService);
  private currentDeliveryTourDate: string | null = null;
  currentDelivery!: IDriverDelivery;
  processedDelivery!: ProcessedDelivery;

  constructor() {
    this.deliveryMarketsTourPageService.deliveryTour$
      .pipe(takeUntilDestroyed())
      .subscribe((deliveryTour: DeliveryTour) => {
        this.currentDeliveryTourDate = deliveryTour.date;
      });

    // Note: Call current navigation within constructor, because navigation has potentially finished already within ngOnInit.
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.processedDelivery = navigation.extras.state['processedDelivery'];
      this.currentDelivery = navigation.extras.state['originalDelivery'];
    }
  }

  onPrintProcessedDelivery() {
    if (this.processedDelivery) {
      this.printService.printDeliverySlip(
        this.currentDelivery,
        false,
        this.processedDelivery.signature
      );
    }
  }

  onClickBackToTour() {
    this.goToCurrentDeliveryTourPage();
  }

  private goToCurrentDeliveryTourPage(): void {
    this.router.navigate([
      MOBILE_ROUTE_PATHS.DELIVERY_MARKETS_TOUR + '/' + this.currentDeliveryTourDate,
    ]);
  }
}
