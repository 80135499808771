import { IEntity } from './entity.interface';
import { IProduct } from "./product.interface";

export enum DeliveryType {
  PRIMARY = 'PRIMARY',
  RESUPPLY = 'RESUPPLY',
}

export enum DeliveryStatus {
  CREATED = 'CREATED',
  READY_TO_DELIVER = 'READY_TO_DELIVER',

  DELIVERED = 'DELIVERED',

  // -> Abgeschlossen wird zu fakturiert
  COMPLETED = 'COMPLETED',
}

// TODO: rename to DeliverySignaturePackage
export interface SignaturePackage {
  base64: string;
  monoImageTag: string;
  width: number;
  signaturePerson: string;
}

// TODO: Add typing for: IDelivery.property + Postfix "Label". driverId vs driver?
export interface DataTableDelivery extends IDelivery {
  deliveryDateLabel: string;
  driverLabel: string;
  marketNameLabel: string;
  marketDeliveryStreet: string;
  marketDeliveryPostalCode: string;
  marketDeliveryCity: string;
}

export interface IDiscount extends IEntity {
  targetEntity?: string; // TODO: Check if this ? is OK. It is added because CreateDeliveryInput doesnt have a targetEntity, as it is automatically set to DELIVERY.
  calculationType: string;
  discountValue: number;
  note?: string | null;
}

export interface IDelivery extends IEntity {
  marketId?: number | null;
  driverId?: number | null;
  vehicleId?: number | null;
  deliverySlipNumber: string | null;
  hasPostDeliveryCorrections: boolean;
  deliverySlipEmailSent: boolean;
  discount?: Omit<IDiscount, 'id'> | null; // TODO: check for ID omittance... used for createDeliveryInput where no discount exists yet, potentially.

  isActive: boolean;
  deliveryDate: Date | string;                 // TODO: Check format. Make it one-type.
  deliveryDeliveredDate: Date | string | null; // TODO: Check format. Make it one-type.
  type: DeliveryType;
  status: DeliveryStatus;
  driverNote?: string;
  deliveryProducts?: any | null; // TODO: Proper typing, get rid of any[]. Collision with server entity:   deliveryProducts = new Collection<DeliveryProductEntity>(this);
  // deliveryProducts?: IDeliveryProduct[] | null;

  photos?: any;

  processedDelivery?: IProcessedDelivery | null; // TODO: Proper typing.
}

export interface IDriverDelivery extends Pick<IDelivery, 'id' | 'marketId' | 'deliveryDate' | 'deliverySlipNumber' | 'deliveryProducts' | 'type' | 'status' | 'driverNote' | 'vehicleId'> {
  marketName: string;
  marketAddress: string;
  marketPostalCode: string;
  marketPhone: string;
  marketDeliverySlipEmail: string;
  isDelivered: boolean;
}

export interface DeliveryTour {
  date: string;
  // calendarWeek: number; // TODO
  numMarkets: number;
  isToday: boolean;
  deliveries: IDriverDelivery[];
}

export interface IDeliveryProduct extends IEntity {
  deliveryId: number;
  productId: number;
  targetQuantity: number | null;
  actualQuantity?: number | null;
  returnQuantity?: number | null;
}

export interface IDriverDeliveryProduct extends IDeliveryProduct {
  product: IProduct;
}

// Delivery -> DeliveryDraft -> ProcessedDelivery

// TODO: add tests for conversion.
// TODO: prefix with "I" for Interface?
// export interface DeliveryDraft {
//   id?: number;
//   deliveryId: number;
//   deliveryProducts: Partial<IDriverDeliveryProduct>[];
//   signature: {
//     package: SignaturePackage | null;
//     person: {
//       title: 'Herr' | 'Frau' | 'keine Angabe';
//       name: string;
//     };
//   };
//   messageFromMarket: string;
//   lastModified: Date;
// }
export interface DeliveryDraft {
  deliveryId: number;  // primary key // acts as main dexie table id
  deliveryProducts: Partial<IDriverDeliveryProduct>[];
  signature: {
    package: SignaturePackage | null;
    person: {
      title: 'Herr' | 'Frau' | 'keine Angabe';
      name: string;
    };
  };
  messageFromMarket: string;
  lastModified: Date;
}

// TODO: rename/refactor: Make it clear which itnerface is used for which client... and which is a shared base interface.
// ^ because there exists another ProcessedDelivery interface
export interface IProcessedDelivery {
  // deliveryId: number; // Also acts as main dexie table id
  messageFromMarket: string;

  // backend entity?
  signatureName?: string;
  signatureImage?: string; // base64

  // frontend
  signature?: SignaturePackage;

  deliveredAtMarketDate: Date;
  // deliveryProducts: object[]; // TODO: Type
  // syncStatus: LocalDeliveryDataSyncStatus;
}
