<ng-container *ngIf="deliveryDetailsPageService.delivery$ | async as delivery">
  <ion-content>

    <!--  TODO: check for usage of alert box for BEREITS AUSGELIEFERT-->
    <!--  <ion-alert header="Header" sub-header="Sub Header" message="Message"></ion-alert>-->


    <!-- Wenn bereits ausgeliefert, dann geht aktiv nur noch: Fotos machen, Nachrichten senden -->
    <!-- Passiv geht nur noch: Liefermengen sehen - alles in readonly, keine Editier-Funktionen/Mutations  -->
    <!--  TODO: READONLY delivery overview... deliveryProduct quantities + signature , timestamp , etc-->

    <ion-card color="light">
      <ion-card-header>
        <ion-card-title *ngIf="delivery.isDelivered"
                        style="display: flex; align-items: center;"
                        class="prf-color-success">
          <ion-icon name="checkmark-circle-sharp" class="mr-1"></ion-icon>
          Bereits ausgeliefert
        </ion-card-title>
        <ion-card-title>{{ delivery.marketName }}</ion-card-title>
        <ion-card-subtitle>{{ delivery.marketAddress }}</ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>
        <ion-button
          fill="solid"
          color="primary"
          href="https://www.google.de/maps/dir//{{delivery.marketAddress}}"
          target="_blank"
          style="width: 100%;"
        >
          <ion-icon name="navigate-outline" class="mr-2"></ion-icon>
          Navigation starten
        </ion-button>
      </ion-card-content>
    </ion-card>

    <!--    <ion-card color="danger">-->
    <!--      <ion-card-header>-->
    <!--        <ion-card-title>-->
    <!--          <ion-icon name="alert-circle-outline"></ion-icon>-->
    <!--          Meldung an alle Fahrer-->
    <!--          </ion-card-title>-->
    <!--        <ion-card-subtitle>-->
    <!--          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquid.-->
    <!--        </ion-card-subtitle>-->
    <!--      </ion-card-header>-->
    <!--      <ion-card-content>-->
    <!--        <div class="text-right">-->
    <!--          28.10.2023-->
    <!--        </div>-->
    <!--      </ion-card-content>-->
    <!--    </ion-card>-->

    <!--    <ion-card>-->
    <!--      <ion-card-header>-->
    <!--        <ion-card-title>-->
    <!--          <ion-icon name="alert-circle-outline"></ion-icon>-->
    <!--          Meldung zum Markt-->
    <!--        </ion-card-title>-->
    <!--        <ion-card-subtitle>-->
    <!--          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquid.-->
    <!--        </ion-card-subtitle>-->
    <!--      </ion-card-header>-->
    <!--      <ion-card-content>-->
    <!--        <div class="text-right">-->
    <!--          25.10.2023-->
    <!--        </div>-->
    <!--      </ion-card-content>-->
    <!--    </ion-card>-->

    <ion-card *ngIf="delivery.driverNote" style="background: #222428;">
      <ion-card-header>
        <ion-card-title class="flex align-items-center text-white"
                        style="border-bottom: 1px solid #353639; padding-bottom: 8px;">
          <ion-icon name="alert-circle-outline" class="mr-1" />
          Hinweis zur Lieferung
        </ion-card-title>
        <ion-card-subtitle class="mt-3 text-white" style="font-size: 1.2em;">
          {{ delivery.driverNote }}
        </ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>
        <!--      <div class="text-right">-->
        <!--        27.10.2023-->
        <!--      </div>-->
      </ion-card-content>
    </ion-card>

    <!--  PRINT DEBUG REMOVE-->
<!--    <ion-card *ngIf="delivery.driverNote && delivery.driverNote.toLowerCase() === 'drucken'"-->
<!--              style="background: #000104b5">-->
<!--      <ion-card-header>-->
<!--        <ion-card-title class="flex align-items-center text-white"-->
<!--                        style="border-bottom: 1px solid #353639; padding-bottom: 8px;">-->
<!--          <ion-icon name="print-outline" class="mr-1" />-->
<!--          Druck-Test-->
<!--        </ion-card-title>-->

<!--        <ion-card-title><span style="color: white !important;">Druckeinstellungen</span></ion-card-title>-->

<!--        <ion-radio-group [(ngModel)]="isDraftPrint">-->
<!--          <ion-list-header>-->
<!--            <ion-label><span style="color: white !important;">ENTWURF</span></ion-label>-->
<!--          </ion-list-header>-->
<!--          <ion-item>-->
<!--            <ion-label>Ja</ion-label>-->
<!--            <ion-radio [value]="true"></ion-radio>-->
<!--          </ion-item>-->
<!--          <ion-item>-->
<!--            <ion-label>Nein</ion-label>-->
<!--            <ion-radio [value]="false"></ion-radio>-->
<!--          </ion-item>-->
<!--        </ion-radio-group>-->

<!--        <div class="mt-3">-->
<!--          <ion-button class="mt-3" (click)="onClickPrintDeliverySlip(delivery, isDraftPrint)">-->
<!--            Drucken-->
<!--          </ion-button>-->
<!--        </div>-->
<!--      </ion-card-header>-->
<!--    </ion-card>-->

    <!--  END OF PRINT DEBUG-->

    <!--  <div *ngIf="!delivery.driverNote" class="ion-padding" style="display: flex; align-items: center; justify-content: center;">-->
    <!--    <ion-icon name="alert-circle-outline" size="medium" class="mr-2"></ion-icon>-->
    <!--    <ion-text>Keine Meldungen zur Lieferung vorhanden.</ion-text>-->
    <!--  </div>-->


    <!--  <ul>-->
    <!--    <li>-->
    <!--      Meldungen/Nachrichten bzgl. des Markets-->
    <!--      <ul>-->
    <!--        <li>Nachricht, für ALLE Fahrer und ALLE Märkte.</li>-->
    <!--        <li>Nachricht, die im Markt hinterlegt ist.</li>-->
    <!--        <li>Nachricht, die NUR bei dieser Lieferung hinterlegt ist.</li>-->
    <!--      </ul>-->
    <!--    </li>-->
    <!--    <br>-->
    <!--    <li>Adresse und TelefonNr des Marktes</li>-->
    <!--    <li>Kartenansicht der Adresse</li>-->
    <!--    <li>Navigation zum Markt</li>-->
    <!--  </ul>-->

  </ion-content>
</ng-container>
