import { Component, EventEmitter, Input, Output, ElementRef, QueryList, ViewChildren, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from '../../../../../../core/components/input/input.component';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonContent } from '@ionic/angular';
import { IDriverDeliveryProduct } from '@prf/shared/domain';

export type QuantityType = 'actualQuantity' | 'returnQuantity';

export interface ProductInputFocusEvent {
  item: Partial<IDriverDeliveryProduct>;
  quantityType: QuantityType;
}

export interface ProductInputChangeEvent {
  item: Partial<IDriverDeliveryProduct>;
  quantityType: QuantityType;
  value: number;
}

@Component({
  selector: 'prf-product-amount-grid',
  standalone: true,
  imports: [CommonModule, InputComponent, FormsModule, IonicModule],
  templateUrl: './product-amount-grid.component.html',
  styleUrls: ['./product-amount-grid.component.scss'],
})
export class ProductAmountGridComponent {
  private ionContent = inject(IonContent);

  @Input({ required: true })
  items!: Partial<IDriverDeliveryProduct>[];

  @Input()
  isEditable: boolean = true;

  @Output()
  itemFocus = new EventEmitter<ProductInputFocusEvent>();

  @Output()
  productsChange = new EventEmitter<Partial<IDriverDeliveryProduct>[]>();

  @ViewChildren('gridRow') gridRows!: QueryList<ElementRef>;

  focussedItem: Partial<IDriverDeliveryProduct> | null = null;
  focussedQuantityType: QuantityType | null = null;

  updateQuantity(item: Partial<IDriverDeliveryProduct>, quantityType: QuantityType, value: number) {
    item[quantityType] = value;
    this.emitChanges();
  }

  private emitChanges() {
    this.productsChange.emit([...this.items]);
  }

  handleConfirmItem(): void {
    this.toggleCurrentRowsQuantityType();
  }

  handleNextItem(): void {
    if (this.focussedItem) {
      const currentIndex = this.items.indexOf(this.focussedItem);
      // Check if there's a next item
      if (currentIndex !== -1 && currentIndex < this.items.length - 1) {
        const nextItem = this.items[currentIndex + 1];
        this.setFocussedItem(nextItem as Partial<IDriverDeliveryProduct>, this.focussedQuantityType!);
      }
    }
  }

  private toggleCurrentRowsQuantityType(): void {
    if (this.focussedQuantityType === 'actualQuantity') {
      this.setFocussedItem(this.focussedItem!, 'returnQuantity')
    } else if(this.focussedQuantityType === 'returnQuantity') {
      this.setFocussedItem(this.focussedItem!, 'actualQuantity')
    }
  }

  setFocussedItem(item: Partial<IDriverDeliveryProduct>, quantityType: QuantityType): void {
    this.focussedItem = item;
    this.focussedQuantityType = quantityType;
    this.itemFocus.emit({ item, quantityType });

    // Use setTimeout to ensure DOM is updated
    setTimeout(() => {
      const index = this.items.indexOf(item);
      const rowElement = this.gridRows.get(index)?.nativeElement;
      if (rowElement) {
        const yOffset = rowElement.offsetTop - 100; // 100px from top
        this.ionContent.scrollToPoint(0, yOffset, 300); // x, y, duration in ms
      }
    });
  }

  itemIsFocussed(item: Partial<IDriverDeliveryProduct>, quantityType: QuantityType): boolean {
    return this.focussedItem === item && this.focussedQuantityType === quantityType;
  }
}
