import { inject, Injectable } from '@angular/core';
import { AppIndexedDb } from '../db/app-indexed-db.service';
import { catchError, of, tap } from 'rxjs';
import { InspectMobileService } from '../../../graphql/inspect-operations.generated';
import { APP_VERSION } from '@prf/shared/domain';
import { detectDevice } from './device-info.util';

@Injectable({
  providedIn: 'root',
})
export class MobileInspectService {
  private appIndexedDb = inject(AppIndexedDb);
  private inspectMobileService = inject(InspectMobileService);

  constructor() {}

  async cleanupLocalDb() {
    return this.appIndexedDb.cleanupLocalDb();
  }

  async fixLocalDeliveries() {
    console.log('fixLocalDeliveries - ');
    // return this.appIndexedDb.updateSpecificDeliveryProduct();

    // TEMP RESET DEV LOCAL TEST
    // return this.appIndexedDb.handleWrongDeliveryReset(10103);
    // return this.appIndexedDb.updateDeliverySyncStatus(10103);



    // PROD!!
    // return this.appIndexedDb.handleWrongDeliveryReset(12605);

    // ACTIVATE THIS for version B
    return this.appIndexedDb.updateDeliverySyncStatus(12605);
  }

  async triggerInspect() {
    console.log('triggerInspect - ');

    try {
      const [inspectTableEntries, storageEstimate, persistGranted, deviceInfo] = await Promise.all([
        this.appIndexedDb.getInspectTableEntries(),
        navigator.storage.estimate().catch(() => ({ usage: undefined, quota: undefined })),
        this.ensureStoragePersistence().catch(() => false),
        detectDevice().catch(error => {
          console.warn('Error detecting device info:', error);
          return undefined;
        })
      ]);

      const formattedStorage = {
        usage: storageEstimate.usage ? this.bytesToSize(storageEstimate.usage) : undefined,
        quota: storageEstimate.quota ? this.bytesToSize(storageEstimate.quota) : undefined,
        usageDetails: (storageEstimate as any).usageDetails ? this.bytesToSizeDetails((storageEstimate as any).usageDetails) : undefined,
        persistGranted,
      };

      const inspectPayload = JSON.stringify({
        pmVersion: APP_VERSION,
        deviceInfo,
        idb: inspectTableEntries,
        storage: formattedStorage,
      });

      this.inspectMobileService.mutate({
        dataString: inspectPayload,
      })
        .pipe(
          tap((result) => {
            // console.log('TAP inspect mut - result', result);
            // if (result.data?.inspect) {
            //   console.log('TRUE RESPONSE inspect mut - ');
            // }
          }),
          catchError((error) => {
            console.error('Error calling inspect MUT', error);
            return of(false);
          }),
        )
        .subscribe();
    } catch (error) {
      console.error('Error in triggerInspect method', error);
    }
  }

  private async ensureStoragePersistence() {
    let persistGranted = false;
    try {
      if (navigator.storage && navigator.storage.persisted) {
        persistGranted = await navigator.storage.persisted();
        console.log(`Current persistent storage status: ${persistGranted}.`);

        // Request persistence if not already granted
        if (!persistGranted && navigator.storage.persist) {
          persistGranted = await navigator.storage.persist();
          console.log(`Persistence storage request granted: ${persistGranted}.`);
        }
      } else {
        console.log("Persistent storage API not supported.");
      }
    } catch (error) {
      console.warn('Error checking storage persistence:', error);
    }
    return persistGranted;
  }

  private bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
  }

  private bytesToSizeDetails(details: any) {
    Object.entries(details).forEach(([key, value]: [string, any]) => {
      details[key] = this.bytesToSize(value);
    });

    return details;
  }
}
