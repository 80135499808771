import {
  APP_INITIALIZER,
  ApplicationRef,
  enableProdMode, ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, Router, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { GraphQLModule } from './app/graphql/graphql.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { provideServiceWorker, SwUpdate } from '@angular/service-worker';
import { AuthInterceptor } from "./app/shared/interceptors/auth.interceptor";

import * as Sentry from "@sentry/angular";

Sentry.init({
  dsn: "https://35ec04fdac426fb2aab24f48c1f8b079@o4508748729286656.ingest.de.sentry.io/4508748733546576",
  integrations: [],
});

if (environment.production) {
  enableProdMode();
}

export const checkForUpdates = (swUpdate: SwUpdate): (() => Promise<any>) => {
  return (): Promise<void> =>
    new Promise((resolve) => {
      swUpdate.checkForUpdate().then(() => {
        swUpdate.versionUpdates.subscribe(event => {
          "New PWA version available."
          if (event.type === 'VERSION_READY') {
            window.location.reload();
          }
        });
      });
      resolve();
    });
};

(async () => {
  // TODO: extract appConfig, same as in client-hq
  const appRef: ApplicationRef = await bootstrapApplication(AppComponent, {
    providers: [
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler(),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
      // { provide: APP_INITIALIZER, useFactory: checkForUpdates, multi: true, deps: [SwUpdate] },
      importProvidersFrom(
        [
          IonicModule.forRoot({
            animated: true,
            // navAnimation:
            mode: 'md', // Force android look.
          }),
        ],
        HttpClientModule,
        GraphQLModule,

      ),
      provideRouter(routes),
      provideServiceWorker('ngsw-worker.js', {
        // enabled: !isDevMode(),
        enabled: true,
        registrationStrategy: 'registerImmediately',
      }),
    ],
  });

  defineCustomElements(window);
})();
