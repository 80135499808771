export function escape(text: string | number): string {
  if (typeof text !== 'string') {
    text = text.toString();
  }

  const xmlEscapes: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&apos;'
  };

  return text.replace(/[&<>"']/g, char => xmlEscapes[char]);
}
