import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MOBILE_ROUTE_PATHS } from '../../app.routes';
import { ActivatedRoute, Router } from '@angular/router';
import { IDriverDelivery } from '@prf/shared/domain';
import { AppIndexedDb, ProcessedDelivery } from '../../shared/services/db/app-indexed-db.service';
import { EpsonPrintCommunicationService } from '../../shared/services/print/epson-print-communication.service';

@Component({
  selector: 'prf-delivery-done-details',
  templateUrl: './delivery-done-details.page.html',
  styleUrls: ['./delivery-done-details.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class DeliveryDoneDetailsPage implements OnInit {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private printService = inject(EpsonPrintCommunicationService);
  private indexedDb = inject(AppIndexedDb);

  currentDelivery?: IDriverDelivery;
  processedDelivery?: ProcessedDelivery;

  constructor() {
    // Try to get data from navigation state first
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.processedDelivery = navigation.extras.state['processedDelivery'];
      this.currentDelivery = navigation.extras.state['originalDelivery'];
    }
  }

  async ngOnInit() {
    // If we don't have currentDelivery (e.g., after page reload via EPOS print), fetch it using the ID from route params
    if (!this.currentDelivery || !this.processedDelivery) {
      const deliveryId = Number(this.route.snapshot.paramMap.get('deliveryId'));

      if (!isNaN(deliveryId)) {
        try {
          // Fetch both delivery and processed delivery data
          const [delivery, processedDelivery] = await Promise.all([
            this.indexedDb.getDeliveryById(deliveryId),
            this.indexedDb.getProcessedDeliveryById(deliveryId)
          ]);

          this.currentDelivery = delivery;
          this.processedDelivery = processedDelivery;

          // If we don't have the delivery data, redirect to tour page
          if (!this.currentDelivery || !this.processedDelivery) {
            console.error('Delivery or processed delivery not found:', deliveryId);
            this.goToCurrentDeliveryTourPage();
            return;
          }
        } catch (error) {
          console.error('Error fetching delivery data:', error);
          this.goToCurrentDeliveryTourPage();
        }
      } else {
        console.error('Invalid delivery ID');
        this.goToCurrentDeliveryTourPage();
      }
    }
  }

  onPrintProcessedDelivery() {
    if (this.processedDelivery && this.currentDelivery) {
      // Create delivery object with updated products
      const deliveryForPrinting = {
        ...this.currentDelivery,
        // Note: original delivery (from BE) always has 0 quantities, therefore the entered quantities are being used here.
        deliveryProducts: this.processedDelivery.deliveryProducts
      };

      this.printService.printDeliverySlip(
        deliveryForPrinting,
        false,
        this.processedDelivery.signature,
        this.processedDelivery
      );
    }
  }

  onClickBackToTour() {
    this.goToCurrentDeliveryTourPage();
  }

  private goToCurrentDeliveryTourPage(): void {
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
    this.router.navigate([
      MOBILE_ROUTE_PATHS.DELIVERY_MARKETS_TOUR + '/' + today,
    ]);
  }
}
