import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DeliveryDetailsPageService } from '../../delivery-details-page.service';
import { FormsModule } from '@angular/forms';
import { InputComponent } from '../../../../core/components/input/input.component';

@Component({
  selector: 'prf-delivery-market-info-tab',
  templateUrl: './delivery-market-info-tab.component.html',
  styleUrls: ['./delivery-market-info-tab.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, InputComponent]
})
export class DeliveryMarketInfoTabComponent {
  protected deliveryDetailsPageService = inject(DeliveryDetailsPageService);

  constructor() {
  }

}
