<div class="ion-padding">

  <h2 class="flex">
    <ion-icon name="checkmark-circle-sharp" class="prf-color-success mr-1" size="large" style="flex-basis: 65px;"></ion-icon>
    @if (currentDelivery) {
      Lieferung {{currentDelivery.deliverySlipNumber}} erfolgreich abgeschlossen.
    }
  </h2>

  @if (currentDelivery) {
    <p>
      {{ currentDelivery.marketName }}
      {{ currentDelivery.marketAddress }}
    </p>
  }
<!--  TODO: ausgeliefert um X Uhr via processedDelivery - entgegengenommen von Herr X -->
</div>

@if (processedDelivery?.printXml) {
  <div>
    <ion-button
      expand="block"
      color="tertiary"
      style="height: 120px;"
      (click)="onPrintProcessedDelivery()"
      [disabled]="!processedDelivery">
      <ion-icon name="print-outline" slot="start"></ion-icon>
      Lieferschein drucken
    </ion-button>
  </div>
}

<ion-footer class="dark">
  <div class="ion-padding text-center">
    <ion-button
      (click)="onClickBackToTour()">
      Weiter zur heutigen Tour
      <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
    </ion-button>
  </div>
</ion-footer>


<!--1: burst confetti on successful finish-->
<!--https://www.kirilv.com/canvas-confetti == Realistic Look/
https://loonywizard.github.io/js-confetti/
https://confettijs.org/-->

<!--2: show sync status: server loading... -- DONE, oder "wird nachsynchronisiert-->
