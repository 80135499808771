import { UAParser } from 'ua-parser-js';

export interface DeviceInfo {
  browser: {
    n: string | undefined;
    v: string | undefined;
  };
  device: string | undefined;
  os: string | undefined;
  permissions: {
    camera: boolean | undefined;
    geolocation: PermissionState | undefined;
  };
}

async function checkCameraPermission(): Promise<boolean | undefined> {
  try {
    if (!navigator.mediaDevices?.enumerateDevices) {
      return undefined;
    }
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.some(device =>
      device.kind === 'videoinput' && device.label !== ''
    );
  } catch (error) {
    console.warn('Error checking camera permission:', error);
    return undefined;
  }
}

async function checkPermission(name: PermissionName): Promise<PermissionState | undefined> {
  try {
    if (!navigator.permissions) {
      return undefined;
    }
    const result = await navigator.permissions.query({ name });
    return result.state;
  } catch (error) {
    console.warn(`Error checking ${name} permission:`, error);
    return undefined;
  }
}

export async function detectDevice(): Promise<DeviceInfo | undefined> {
  try {
    const result = UAParser();

    // Query permissions in parallel
    const [cameraPermission, locationPermission] = await Promise.all([
      checkCameraPermission(),
      checkPermission('geolocation')
    ]);

    return {
      browser: {
        n: result.browser.name,
        v: result.browser.version,
      },
      device: result.device.vendor && result.device.model
        ? `${result.device.vendor} ${result.device.model}`
        : undefined,
      os: result.os.name && result.os.version
        ? `${result.os.name} ${result.os.version}`
        : undefined,
      permissions: {
        camera: cameraPermission,
        geolocation: locationPermission
      }
    };
  } catch (error) {
    console.warn('Error detecting device info:', error);
    return undefined;
  }
}
