import { inject, Injectable } from '@angular/core';
import { AppIndexedDb } from '../db/app-indexed-db.service';
import { catchError, of, tap } from 'rxjs';
import { InspectMobileService } from '../../../graphql/inspect-operations.generated';
import { APP_VERSION } from '@prf/shared/domain';

@Injectable({
  providedIn: 'root',
})
export class MobileInspectService {
  private appIndexedDb = inject(AppIndexedDb);
  private inspectMobileService = inject(InspectMobileService);

  constructor() {}

  async cleanupLocalDb() {
    return this.appIndexedDb.cleanupLocalDb();
  }

  async fixLocalDeliveries() {
    return this.appIndexedDb.updateSpecificDeliveryProduct();
  }

  async triggerInspect() {
    console.log('triggerInspect - ');

    try {
      const inspectTableEntries = await this.appIndexedDb.getInspectTableEntries();
      const storageEstimate = await navigator.storage.estimate();

      // Check if storage is already persisted
      const persistGranted = await this.ensureStoragePersistence();
      console.log('triggerInspect - persistGranted', persistGranted);

      const formattedStorage = {
        usage: storageEstimate.usage ? this.bytesToSize(storageEstimate.usage) : undefined,
        quota: storageEstimate.quota ? this.bytesToSize(storageEstimate.quota) : undefined,
        usageDetails: (storageEstimate as any).usageDetails ? this.bytesToSizeDetails((storageEstimate as any).usageDetails) : undefined,
        persistGranted,
      };

      const inspectPayload = JSON.stringify({
        pmVersion: APP_VERSION,
        idb: inspectTableEntries,
        storage: formattedStorage
      });

      this.inspectMobileService.mutate({
        dataString: inspectPayload,
      })
        .pipe(
          tap((result) => {
            // console.log('TAP inspect mut - result', result);
            // if (result.data?.inspect) {
            //   console.log('TRUE RESPONSE inspect mut - ');
            // }
          }),
          catchError((error) => {
            console.error('Error calling inspect MUT', error);
            return of(false);
          }),
        )
        .subscribe();
    } catch (error) {
      console.error('Error in triggerInspect method', error);
    }
  }

  private async ensureStoragePersistence() {
    let persistGranted = false;
    if (navigator.storage && navigator.storage.persisted) {
      persistGranted = await navigator.storage.persisted();
      console.log(`Current persistent storage status: ${persistGranted}.`);

      // Request persistence if not already granted
      if (!persistGranted && navigator.storage.persist) {
        persistGranted = await navigator.storage.persist();
        console.log(`Persistence storage request granted: ${persistGranted}.`);
      }
    } else {
      console.log("Persistent storage API not supported.");
    }
    return persistGranted;
  }

  private bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
  }

  private bytesToSizeDetails(details: any) {
    Object.entries(details).forEach(([key, value]: [string, any]) => {
      details[key] = this.bytesToSize(value);
    });

    return details;
  }

}
