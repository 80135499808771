interface ImageSettings {
  maxWidth: number;
  maxHeight: number;
  threshold: number;
  align: 'left' | 'center' | 'right';
}

interface ResizedDimensions {
  width: number;
  height: number;
}

interface EpsonMonoImageResult {
  tag: string;
  width: number;
  height: number;
}

export async function dataUrlToEpsonMonoImageTag(
  dataUrl: string,
  options: Partial<ImageSettings> = {},
): Promise<EpsonMonoImageResult | null> {
  const defaults: ImageSettings = {
    maxWidth: 360,
    maxHeight: 90,
    threshold: 0.5,
    align: 'left',
  };

  const settings: ImageSettings = { ...defaults, ...options };

  try {
    const imageData = await dataUrlToImageData(dataUrl, settings);
    const monoData = imageDataToMono(imageData, settings);
    const epsonMonoImage = encodeMonoImageTag(monoData, imageData.width, imageData.height, settings);
    return {
      tag: epsonMonoImage,
      width: imageData.width,
      height: imageData.height
    };
  } catch (error) {
    console.error('Error converting data URL to Epson mono image:', error);
    return null;
  }
}

function calculateResizedDimensions(
  imgWidth: number,
  imgHeight: number,
  maxWidth: number,
  maxHeight: number,
): ResizedDimensions {
  let width = imgWidth;
  let height = imgHeight;

  // Calculate aspect ratio
  const aspectRatio = imgWidth / imgHeight;

  // Resize if image exceeds maximum dimensions
  if (width > maxWidth) {
    width = maxWidth;
    height = Math.round(width / aspectRatio);
  }

  if (height > maxHeight) {
    height = maxHeight;
    width = Math.round(height * aspectRatio);
  }

  // Ensure width is multiple of 8 for optimal printing
  width = Math.floor(width / 8) * 8;

  return { width, height };
}

function dataUrlToImageData(dataUrl: string, settings: ImageSettings): Promise<ImageData> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      // Calculate optimal dimensions
      const dimensions = calculateResizedDimensions(
        img.width,
        img.height,
        settings.maxWidth,
        settings.maxHeight,
      );

      const canvas = document.createElement('canvas');
      canvas.width = dimensions.width;
      canvas.height = dimensions.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }

      // Set white background
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Always use nearest neighbor sampling for speed
      ctx.imageSmoothingEnabled = false;

      // Draw resized image
      ctx.drawImage(img, 0, 0, dimensions.width, dimensions.height);

      // Apply immediate black and white conversion
      const imageData = ctx.getImageData(0, 0, dimensions.width, dimensions.height);
      for (let i = 0; i < imageData.data.length; i += 4) {
        const avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
        const bw = avg < settings.threshold * 255 ? 0 : 255;
        imageData.data[i] = bw;
        imageData.data[i + 1] = bw;
        imageData.data[i + 2] = bw;
      }
      ctx.putImageData(imageData, 0, 0);

      resolve(ctx.getImageData(0, 0, dimensions.width, dimensions.height));
    };
    img.onerror = (error) => reject(new Error('Failed to load image: ' + error));
    img.src = dataUrl;
  });
}

function imageDataToMono(imageData: ImageData, settings: ImageSettings): Uint8Array {
  const { width, height, data } = imageData;
  const bytesPerLine = width / 8;
  const monoData = new Uint8Array(bytesPerLine * height);

  // Optimized pixel processing
  for (let y = 0; y < height; y++) {
    const lineOffset = y * bytesPerLine;
    for (let x = 0; x < width; x += 8) {
      const byteOffset = lineOffset + x / 8;
      let byte = 0;

      // Process 8 pixels at once
      for (let bit = 0; bit < 8; bit++) {
        const i = (y * width + x + bit) * 4;
        const brightness = (data[i] * 0.299 + data[i + 1] * 0.587 + data[i + 2] * 0.114) / 255;
        if (brightness < settings.threshold) {
          byte |= 1 << (7 - bit);
        }
      }
      monoData[byteOffset] = byte;
    }
  }

  return monoData;
}

function encodeMonoImageTag(
  monoData: Uint8Array,
  width: number,
  height: number,
  settings: ImageSettings,
): string {
  let binaryString = '';
  for (let i = 0; i < monoData.length; i++) {
    binaryString += String.fromCharCode(monoData[i]);
  }

  return `<image width="${width}" height="${height}" align="${settings.align}" color="color_1" mode="mono">${btoa(binaryString)}</image>`;
}

// Usage example:
// const imageOptions: Partial<ImageSettings> = {
//   maxWidth: 360,
//   maxHeight: 90,
// };
//
// const base64DataUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfsAAAFmCAYAAA...';
//
// dataUrlToEpsonMonoImageTag(base64DataUrl, imageOptions)
//   .then((epsonMonoImage) => {
//     if (epsonMonoImage) {
//       console.log('Epson mono image XML:', epsonMonoImage);
//     }
//   })
//   .catch((error) => {
//     console.error('Error:', error);
//   });
